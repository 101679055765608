<template>
  <div
    class="container size-mobile bg-element-white min-height-second paket"
    style="font-family: Montserrat;padding-left:15px !important;"
  >
  
    <v-dialog />
    <alert-error ref="ae" />
    <form class="d-flex flex-column mt-4" method="POST" @submit.prevent="store()" style="min-height: 88vh;"> 
      <fieldset>
        <legend class="legend-input">Nama Driver</legend>
        <input type="text" name="modal-input-pengirim" class="form-control" autocomplete="off" v-model="data.nama_driver" required="required"/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Nama Pegawai</legend>
        <input type="text" name="modal-input-penerima" class="form-control" autocomplete="off" v-model="data.nama_pegawai" required="required"/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Plat Nomor</legend>
        <input type="text" name="modal-input-resi" class="form-control" autocomplete="off" v-model="data.no_polisi" required="required"/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Pemilik Kendaraan</legend>
        <input type="text" name="modal-input-resi" class="form-control" autocomplete="off" v-model="data.pemilik_kendaraan"/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">No Kartu Kendaraan</legend>
        <input type="text" name="modal-input-resi" class="form-control" autocomplete="off" v-model="data.no_kartu_kendaraan"/>
      </fieldset>
      <fieldset>
        <legend class="legend-input">Tujuan</legend>
        <textarea name="tujuan" class="form-control" autocomplete="off" v-model="data.keperluan" required="required"></textarea>
      </fieldset>
      <div class="mb-1">
        <legend class="legend-input mt-3">Status Menginap</legend>
        <div class="d-flex mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="menginap" value="1" v-model="data.menginap"/>
            <label class="form-check-label" for="status">Ya</label>
          </div>
          <div class="form-check ml-4">
            <input class="form-check-input" type="radio" name="menginap" value="0" v-model="data.menginap"/>
            <label class="form-check-label" for="exampleRadios2">Tidak</label>
          </div>
        </div>
      </div>
      <div class="mb-1">
        <legend class="legend-input mt-3">Jenis Kendaraan</legend>
        <div class="d-flex mt-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="status" value="2" v-model="data.jenis_kendaraan" required/>
            <label class="form-check-label" for="status" required>Roda 2</label>
          </div>
          <div class="form-check ml-4">
            <input class="form-check-input" type="radio" name="status" value="4" v-model="data.jenis_kendaraan"/>
            <label class="form-check-label" for="exampleRadios2">Roda 4</label>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <fieldset class="mt-2" style="width:50%">
          <legend class="legend-input">Jam Keluar</legend> 
          <!-- <vue-clock-picker done-text="Selesai" active-color="#1976D2" :required="true" v-model="data.jam_keluar" class="form-control" style="border:0 !important"></vue-clock-picker> -->

          <input type="time" name="modal-input-resi" class="form-control" autocomplete="off" v-model="data.jam_keluar"/>
          <!-- <img style="position: absolute;right: 7%;top: 425px;width: 25px;" src="../../assets/img/clock.png"> -->
        </fieldset>
        <span style="width:20px;"></span>        
        <fieldset class="mt-2" style="width:50%">
          <legend class="legend-input">Jam Masuk</legend> 
          <!-- <vue-clock-picker v-model="data.jam_masuk" class="form-control" style="border:0 !important"></vue-clock-picker> -->
          <input type="time" name="modal-input-resi" class="form-control" autocomplete="off" v-model="data.jam_masuk"/>
           <!-- <img style="position: absolute;left:38%;top: 425px;width: 25px;" src="../../assets/img/clock.png"> -->
        </fieldset>
      </div>
       <!-- <fieldset class="mt-2">
        <legend class="legend-input">Tanggal Diambil</legend> 
        <input type="date" name="tanggal-diambil" class="form-control" autocomplete="off" v-model="dataModal.diambil_pada_tanggal" value="2020-09-15"/>
      </fieldset> -->
      <button class="btn-blue mt-3 mb-3" style="width:100%" :class="{'disable': isSubmit}" type="submit">
        <span v-if="isSubmit">
          <div class="spinner-border text-primary mr-3 spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div> Sedang Menyimpan
        </span>
        <span v-else>
          <b>Simpan</b>
        </span>
      </button>
    </form>
  </div>
</template>
<script>
// import 'vue2-timepicker/dist/VueTimepicker.css'
import Api from "../../Api";
import alertError from "../../components/AlertError.vue";
// import VueTimepicker from 'vue2-timepicker'


export default {
  name: "addPaket",
  components: {
    alertError,
  },
  data() {
    return {
      startDate: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
      isLoading: true,
      data: { 
        user_id: '',
        nama_pegawai: '',
        nama_driver: '',
        keperluan: '',
        workplace_id: '',
        no_polisi: '',
        pemilik_kendaraan: '',
        no_kartu_kendaraan: '',
        jenis_kendaraan: '4',
        jam_keluar: '',
        jam_masuk: '',
        menginap:'0'
      },
      total: 0,
      dataModal: [],
      isModalVisible: false,
      updatedContent: "",
      token: "",
      myData: "",
      isSubmit: false,
      dataFiltered: 0
    };
  },

  async mounted() {
    this.token = JSON.parse(localStorage.getItem("token"));
    this.myData = JSON.parse(localStorage.getItem("cred"));
    // console.log(JSON.parse(localStorage.getItem('cred')));
  },

  methods: {
    async store() {
      try {
        this.data.user_id = this.myData.id;
        this.data.workplace_id = this.myData.id_tempat_kerja;

        console.log(this.data)
        this.isSubmit = true
        await Api.post("satpam/kendaraan", this.data, {
          headers: { Authorization: "Bearer " + this.token }
        }).then((response) => {
          console.log(response)
          this.$router.go(-1)
          // this.$router.push(this.$route.query.redirect || '/kendaraan')
          // console.log(response);
            // this.done = true;
        });
      } catch (error) {
        console.log(error);
      }
      this.isSubmit = false
    }
  }
};
</script> 
<style>
.clock-picker__input{
    max-width: 100% !important;
}
</style>